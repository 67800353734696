const finance = [
  {
    path: '/finance/settlement/list',
    name: 'finance-settlement-list',
    component: () => import('@/views/finance/settlement/settlement-list/SettlementList.vue'),
    meta: {
      layout: 'content',
      resource: 'settlements',
      action: 'list',
    },
  },
]

export default finance
