const onboarding = [
  {
    path: '/processing/payment-methods',
    name: 'payment-methods-list',
    component: () => import('@/views/processing/payment-methods/payment-method-list/PaymentMethodsList.vue'),
    meta: { 
      layout: 'content',
      resource: 'payment_methods',
      action: 'list',

    },
  },
  {
    path: '/processing/payout-methods',
    name: 'payout-methods-list',
    component: () => import('@/views/processing/payout-methods/PayoutMethods.vue'),
    meta: { 
      layout: 'content',
      resource: 'payout_methods',
      action: 'list',
    },

  },
  {
    path: '/processing/payment/list',
    name: 'processing-payment-list',
    component: () => import('@/views/processing/payment/payment-list/PaymentList.vue'),
    meta: {
      layout: 'content',
      resource: 'payments',
      action: 'list',
    },
  },
  {
    path: '/processing/payouts',
    name: 'processing-payouts',
    component: () => import('@/views/processing/payouts/PayoutList.vue'),
    meta: {
      layout: 'content',
      resource: 'payouts',
      action: 'list',
    },
  },
  {
    path: '/processing/payout',
    name: 'processing-payout',
    component: () => import('@/views/processing/payouts/PayoutAddNew.vue'),
    meta: {
      layout: 'content',
      resource: 'payouts',
      action: 'list',
    },
  },
  {
    path: '/processing/refund/list',
    name: 'processing-refund-list',
    component: () => import('@/views/processing/refund/refund-list/RefundList.vue'),
    meta: {
      layout: 'content',
      resource: 'refunds',
      action: 'list',
    },
  },
]

export default onboarding
